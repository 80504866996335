/** @jsx jsx */
import { Card, Heading, jsx, Box, Text, Flex } from "theme-ui"
import { transparentize } from "@theme-ui/color"
import slugify from "@sindresorhus/slugify"
import { Link } from "gatsby"

import ViewCounter from "./ViewCounter"
import Flag from "./Flag"

const CountryCard = ({ country, iso, language }) => {
  return (
    <Link to={`/${slugify(language)}/`} sx={{ variant: `card` }}>
      <Card
        className="country-card"
        sx={{
          variant: `cards.country`,
          display: "flex",
          flexDirection: "column",
          bg: transparentize(`cardBackground`, 0.4),
          "&:hover": {
            bg: transparentize(`cardBackground`, 0),
            "& > h3": {
              color: t => t.colors.accent2,
            },
          },
          height: "100%",
        }}
      >
        <Flex
          sx={{
            flex: "1 1 auto",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box>
            <Flag
              title={country}
              aria-label={country}
              alt={`${country} Flagge`}
              countryCode={iso}
              className="flag-dark"
              svg
            />
          </Box>
          {language ? <ViewCounter id={language} increase={false} /> : null}
        </Flex>

        <Text sx={{ color: "heading", textAlign: "right", fontSize: 0 }}>
          {iso === 'de' ? "football words in..." : "Fußballwortschatz auf..."}
        </Text>
        <Heading
          as="h3"
          sx={{
            textAlign: "right",
            alignSelf: "flex-end",
          }}
        >
          {iso === 'de' ? "german" : language}
        </Heading>
      </Card>
    </Link>
  )
}

export default CountryCard
