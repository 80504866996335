//npm

/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Grid } from "@theme-ui/components"

// components
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import CountryCard from "../components/CountryCard"
import SiteStatBox from "../components/SiteStatsBox"

import IntroText from "../texts/intro"

// component

// graphql query
export const query = graphql`
  query {
    allMysqlLanguage {
      edges {
        node {
          country
          language
          iso
          mysqlId
        }
      }
    }
  }
`

// 💡 all components get location props passed, because gatsby uses react-router under the hood

const Home = ({ data, location }) => {
  const siteTitle = `Dein Fußballwortschatz`

  return (
    <Layout location={location} title={siteTitle}>

      <SiteStatBox />

      <SEO title={siteTitle} />

      <IntroText />

      <Grid
        gap={4}
        columns={["repeat(auto-fill, minmax(250px, 1fr))"]}
        sx={{
          pt: 3,
          pb: 4,
          gridAutoRows: "150px",
        }}
      >
        {data.allMysqlLanguage.edges.map(({ node }) => (
          <CountryCard key={node.mysqlId} {...node} />
        ))}
      </Grid>
    </Layout>
  )
}

// export
export default Home
