import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Mit `}<a href="https://www.fussballwortschatz.de">{`Fussballwortschatz.de`}</a>{` bist du sprachlich bestens gerüstet für das nächste Fußball-Großturnier! Du findest hier die wichtigsten Fußballbegriffe in aktuell 24 Sprachen. Bis auf wenige Ausnahmen gibt es zu allen Begriffen die Übersetzung auch zum Anhören. Der Fachsimpelei im Stadion oder in der Bar mit Fans anderer Nationen steht somit nichts mehr im Wege...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      