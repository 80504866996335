/** @jsx jsx */
import { useState, useEffect } from "react"
// import firebase from "gatsby-plugin-firebase"
// import incrementViews from "../../lib/increment-views"
import { Box, Flex, Text, jsx } from "theme-ui"

import useFirebase from "../hooks/useFirebase"

import { FiEye } from "react-icons/fi"

const SiteStatsBox = () => {
  // set current views
  const [allViewsCount, setAllViewsCount] = useState(0)
  const firebase = useFirebase()

  useEffect(() => {
    if (!firebase) return
    const db = firebase.database();
    const allViewsRef = db.ref(`views`);
    allViewsRef.on(`value`, snapshot => {
      const data = snapshot.val();
      const dataValues = (Object.values(data));
      const addedValues = dataValues.reduce((acc, curr) => acc + curr);
      console.log(addedValues);
      setAllViewsCount(() => addedValues)
    })
  })

  // show
  return (
    <Flex
      sx={{
        alignItems: "center",
        color: "muted",
        fontSize: 0,
        justifyContent: "flex-end",
        position: "absolute",
        top: 0,
        right: 0,
        display: ['none', null, 'flex']
      }}
    >
      <Flex sx={{
        flexDirection: "column",
        alignItems: "center",
        borderRight: t => `1px dashed ${t.colors.muted}`,
        px: 2
      }}>
        <Text sx={{ fontSize: 3, color: `accent2` }}>24</Text>
        <Box>Sprachen</Box>
      </Flex>
      <Flex sx={{
        flexDirection: "column",
        alignItems: "center",
        borderRight: t => `1px dashed ${t.colors.muted}`,
        px: 2
      }}>
        <Text sx={{ fontSize: 3, color: `accent2` }}>600</Text>
        <Box>Übersetzungen</Box>
      </Flex>
      <Flex sx={{
        flexDirection: "column",
        alignItems: "center",
        px: 2
      }}>
        <Text sx={{ fontSize: 3, color: `accent2` }}>{allViewsCount}</Text>
        <Box>Aufrufe</Box>
      </Flex>


    </Flex>
  )
}

export default SiteStatsBox
